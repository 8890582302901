@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@layer base {
  body {
    @apply bg-black-navy;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-4xl sm:text-5xl md:text-6xl;
  }

  h2 {
    @apply text-3xl sm:text-4xl md:text-5xl;
  }

  h3 {
    @apply text-2xl sm:text-3xl md:text-4xl;
  }

  li {
    @apply cursor-pointer;
  }
}

@layer components {
  .about-content {
    @apply text-base font-normal text-primary-light;
  }
  .about-content p {
    @apply mb-[1rem];
  }
  .about-content a {
    @apply underline hover:text-gray-300;
  }
}

@layer components {
  .contact-content {
    @apply text-base font-normal text-primary-light;
  }
  .contact-content a {
    @apply underline hover:text-gray-300;
  }
}

@layer components {
  .news-content {
    @apply break-words text-base text-davys-gray;
  }
  .news-content p {
    @apply mb-[0.2rem];
  }
  .news-content h1,
  .news-content h2,
  .news-content h3,
  .news-content h4 {
    @apply pb-[0.5rem] font-libre-baskerville text-black;
  }
  .news-content h1 {
    @apply text-2xl sm:text-3xl md:text-4xl;
  }
  .news-content h2 {
    @apply text-xl sm:text-2xl md:text-3xl;
  }
  .news-content h3 {
    @apply text-lg sm:text-xl md:text-2xl;
  }
  .news-content h4 {
    @apply text-base sm:text-lg md:text-xl;
  }
  .news-content a {
    @apply underline hover:text-black;
  }
  .news-content blockquote {
    @apply my-[1.0rem] border-l-4 border-dim-gray py-[0.5rem] pl-[1.5rem] italic text-dim-gray;
  }
  .news-content ul {
    @apply list-outside list-disc pl-[1.5rem];
  }
  .news-content ul ul {
    @apply list-outside list-[circle] pl-[1.5rem];
  }
  .news-content ul ul ul {
    @apply list-outside list-[square] pl-[1.5rem];
  }
  .news-content ol {
    @apply list-outside list-decimal pl-[1.5rem];
  }
  .news-content ol ol {
    @apply list-[lower-alpha];
  }
  .news-content ol ol ol {
    @apply list-[lower-roman];
  }
  .news-content ol ol ol ol {
    @apply list-[upper-alpha];
  }
  .news-content ol ol ol ol ol {
    @apply list-[upper-roman];
  }
  .news-content li {
    @apply cursor-text;
  }
  .news-content iframe {
    @apply aspect-video w-full;
  }
}

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}
